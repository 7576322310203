import * as screens from '../../constants/screens';
/**
 *
 * @param {*} ability function get from AbilityContext
 * @param {*} t translation function
 */
export const buildManagerMenuItems = (ability, t) => {
  const menuItems = [];

  if (!ability) {
    return [];
  }

  if (ability.can('view', screens.CREATE_INCIDENT)) {
    menuItems.push({
      icon: undefined,
      title: t('sideMenu:createIncident'),
      path: '/create-incident',
    });
  }

  if (ability.can('view', screens.INCIDENT_LIST)) {
    menuItems.push({
      icon: undefined,
      title: t('sideMenu:incidentList'),
      path: '/incident-list',
    });
  }

  return menuItems;
};
